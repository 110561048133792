import { cn } from 'utils/styleUtils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import { SelectProps } from '@radix-ui/react-select';

interface IProps extends SelectProps {
  value: string | undefined;
  onChange: (state: any) => void;
  data: {
    label: string;
    value: string;
    key?: string | number;
    disabled?: boolean;
  }[];
  placeholder?: string;
  className?: string;
  clearable?: boolean;
}

export default function SelectOptions({
  value,
  onChange,
  data,
  placeholder,
  className,
  clearable,
  ...props
}: IProps) {
  return (
    <div className={cn(className, 'w-52 relative')}>
      <Select onValueChange={onChange} value={value} {...props}>
        <SelectTrigger
          clearable={clearable && !!value}
          onClear={() => onChange('')}
          className="border-slate-200 rounded-md"
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {data.map(item => (
            <SelectItem
              value={item.value}
              key={item.key}
              disabled={item.disabled}
              className="flex items-center justify-between"
            >
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
